@import 'styles/includes.nordr.scss';

.CookieBar {
    position: fixed;

    bottom: 0;
    box-sizing: border-box;
    width: 100%;
    padding: 20px;
    background-color: $colorBrownLight20;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
    z-index: 10000;

    @media print {
        display: none;
    }

    &__Inner {
        display: flex;
        flex-direction: column;

        @include media(md) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: row;

            @include u-wrap(article);
        }
    }

    &__Text {
        margin: 4px 0 6px 0;
        font-size: 1.4rem;
        line-height: 1.5;
        font-weight: 300;
        width: 100%;

        @include media(md) {
            width: 80%;
            padding-right: 100px;
        }

        @include media(lg) {
            padding-right: 150px;
        }

        h2 {
            font-weight: 700;
            line-height: 1.4;
        }

        p {
            font-size: 1.4rem;
        }

        a {
            color: $colorBlueDark100;
            text-decoration: none;
        }
    }

    &__Button {
        justify-content: flex-end;
        margin: 15px auto 0;
        flex-shrink: 0;

        @include media(md) {
            margin-top: 0;
            width: auto;
        }
    }
}
